import React from 'react';
import cx from 'classnames';
import Lottie from 'react-lottie';
import { Props } from '../';
import loader from '../animation/LOADER.json';
import styles from '../style.module.css';

export const LoaderFullPage = ({ loading = true }: Props) => {
  if (!loading) {
    return null;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      filterSize: {
        width: '200%',
        height: '200%',
        x: '-50%',
        y: '-50%',
      },
    },
  };
  const centerAbsolute = cx('absolute', styles.centerAbsolute);
  return (
    <div className="fixed w-100 h-100 o-100 z-999 top-0 left-0 bg-white">
      <div className={centerAbsolute}>
        <Lottie
          options={defaultOptions}
          height={180}
          width={180}
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled
        />
      </div>
    </div>
  );
};
