import React from 'react';
import Lottie from 'react-lottie';
import { Props } from '../';
import loader from '../animation/LOADER_CARD.json';

export const LoaderCard = ({ loading = true }: Props) => {
  if (!loading) {
    return null;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="center w-100">
      <Lottie
        options={defaultOptions}
        width={420}
        isStopped={false}
        isPaused={false}
        isClickToPauseDisabled
      />
    </div>
  );
};
