import React from 'react';
import Lottie from 'react-lottie';
import { Props } from '../';
import loader from '../animation/LOADER.json';

export const Loader = ({ loading = true }: Props) => {
  if (!loading) {
    return null;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      filterSize: {
        width: '200%',
        height: '200%',
        x: '-50%',
        y: '-50%',
      },
    },
  };
  return (
    <div className="pt72 pb72">
      <Lottie
        options={defaultOptions}
        height={180}
        width={180}
        isStopped={false}
        isPaused={false}
        isClickToPauseDisabled
      />
    </div>
  );
};
